import CargasDocumentosService from '../services/cargas-documentos.service';

const initialState = {
    data: [],
    isLoading: false,
    pagination: {
        page: 1,
        per_page: 10,
        total: 0,
        total_pages: 0,
    },
};

export const cargasDocumentos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, params) {
            commit('setIsLoading', true);
            return CargasDocumentosService.get(params).then(
                res => {
                    commit('getSuccess', res);
                    commit('setIsLoading', false);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    commit('setIsLoading', false);
                    return Promise.reject(err.response.data);
                }
            );
        },
        show({ commit }, codigo) {
            return CargasDocumentosService.show(codigo).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        sendMail({ commit }, id) {
            return CargasDocumentosService.sendMail(id).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        destroy({ commit }, { id, data }) {
            return CargasDocumentosService.destroy(id, data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        }
    },
    mutations: {
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        getSuccess(state, res) {
            state.data = res.data;
            state.pagination = res.pagination;
        },
        getFailure(state) {
            state.data = [];
            state.pagination = initialState.pagination;
        }
    }
}
