<template>
    <div class="card">
        <div class="card-content">
            <div class="content">
                <form @submit.prevent="handleSubmit">
                    <b-field
                        label="Descripción"
                        label-position="on-border"
                    >
                        <b-input
                            v-model="form.descripcion"
                            name="descripcion"
                            required
                        ></b-input>
                    </b-field>
                    <br />
                    <button
                        class="button is-block is-fullwidth is-primary"
                        type="submit"
                        :class="{ 'is-loading': loading }"
                    >
                        Guardar
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/utils/Message';

export default {
    name: 'ModalCrearGrupoDocumento',
    props: ['tipo_documento_id', 'grupo_documento'],
    data() {
        return {
            loading: false,
            form: {
                descripcion: '',
            },
        };
    },
    mounted() {
        if (this.grupo_documento) {
            this.form.descripcion = this.grupo_documento.descripcion;
        }
    },
    methods: {
        async handleSubmit() {
            this.loading = true;
            try {
                const req = this.grupo_documento
                    ? this.$store.dispatch('gruposDocumentos/update', {
                        id: this.grupo_documento.id,
                        data: {
                            ...this.form,
                            tipo_documento_id: this.tipo_documento_id
                        },
                    })
                    : this.$store.dispatch('gruposDocumentos/create', {
                        ...this.form,
                        tipo_documento_id: this.tipo_documento_id,
                    });

                const res = await req;
                this.clearForm();
                this.$emit('close');
                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        clearForm() {
            this.form.descripcion = '';
        },
    },
};
</script>

<style>
</style>
