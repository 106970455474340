<template>
    <b-table
        :data="gruposDocumentos"
        :loading="isLoadingGruposDocumentos"
        :paginated="true"
        :per-page="pagination.per_page"
        :current-page="pagination.page"
        :total="paginationTotal"
        @page-change="handleChangePage"
        backend-pagination
    >
        <b-table-column
            field="descripcion"
            label="Descripción"
            v-slot="props"
        >
            {{ props.row.descripcion }}
        </b-table-column>

        <b-table-column
            label="Acciones"
            v-slot="props"
        >
            <b-tooltip label="Editar" type="is-dark">
                <a @click.prevent="openModalEdit(props.row)">
                    <b-icon icon="pencil"></b-icon>
                </a>
            </b-tooltip>

            <b-tooltip label="Eliminar" type="is-dark">
                <a @click.prevent="confirmDelete(props.row)">
                    <b-icon icon="trash-can"></b-icon>
                </a>
            </b-tooltip>
        </b-table-column>

        <template #empty>
            <div class="has-text-centered">No hay registros</div>
        </template>
    </b-table>
</template>

<script>
import Message from '@/utils/Message';
import ModalCrearGrupoDocumento from '@/components/TiposDocumentos/ModalCrearGrupoDocumento.vue';

export default {
    name: 'TablaGrupoDocumentos',
    props: ['tipo_documento_id'],
    data() {
        return {
            isModalActive: false,
            pagination: {
                per_page: 5,
                page: 1,
            },
        };
    },
    computed: {
        gruposDocumentos() {
            return this.$store.state.gruposDocumentos.data;
        },
        isLoadingGruposDocumentos() {
            return this.$store.state.gruposDocumentos.isLoading;
        },
        paginationTotal() {
            return this.$store.state.gruposDocumentos.pagination.total;
        },
    },
    mounted() {
        this.fetchGruposDocumentos();
    },
    methods: {
        async fetchGruposDocumentos() {
            await this.$store.dispatch('gruposDocumentos/get', {
                ...this.pagination,
                tipo_documento_id: this.tipo_documento_id,
            });
        },
        handleChangePage(page) {
            this.pagination.page = page;
            this.fetchGruposDocumentos();
        },
        confirmDelete(grupo_documento) {
            this.$buefy.dialog.confirm({
                message: '¿Está seguro de eliminar este grupo de documentos?',
                onConfirm: () => this.handleDelete(grupo_documento),
            });
        },

        openModalEdit(grupo_documento) {
            this.$buefy.modal.open({
                component: ModalCrearGrupoDocumento,
                parent: this,
                props: {
                    tipo_documento_id: this.tipo_documento_id,
                    grupo_documento: {
                        id: grupo_documento.id,
                        descripcion: grupo_documento.descripcion,
                    }
                },
                events: {
                    close: () => {
                        this.fetchGruposDocumentos();
                    },
                },
            });
        },
        async handleDelete(grupo) {
            try {
                await this.$store.dispatch('gruposDocumentos/delete', grupo.id);

                Message.showSuccessMessage(this, 'Grupo de documentos eliminado');

                this.fetchGruposDocumentos();
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            }
        },
    },
};
</script>
