<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Publicación Individual</h2>
        </div>
        <div class="columns is-justify-content-center">
            <div class="column is-half-desktop">
                <div class="card">
                    <div class="card-content">
                        <form @submit.prevent="handleSubmit">
                            <!-- <b-field label="Trabajador" horizontal>
                                <b-input v-model="form.dni" required />
                            </b-field> -->
                            <autocomplete-trabajador
                                v-model="form.trabajador_id"
                                @input="handleVerify"
                            />
                            <b-field
                                class="mt-4"
                                label="Periodo *"
                                label-position="on-border"
                            >
                                <b-datepicker
                                    @blur="handleVerify"
                                    required
                                    :mobile-native="false"
                                    type="month"
                                    v-model="periodo"
                                    placeholder="Seleccione un periodo"
                                    icon="calendar-today"
                                    trap-focus
                                >
                                </b-datepicker>
                            </b-field>
                            <b-message
                                v-if="
                                    !tiposDocumentosLoading &&
                                    tiposDocumentos.length === 0
                                "
                                type="is-warning"
                            >
                                <div class="is-flex is-align-items-center">
                                    <b-icon icon="alert-circle" />
                                    <span class="ml-1">
                                        No hay TIPOS DE DOCUMENTOS creados para
                                        su empresa. Haga click
                                        <router-link
                                            to="/a/documentos/tipos-documentos"
                                            >aquí</router-link
                                        >
                                        para crear uno.
                                    </span>
                                </div>
                            </b-message>
                            <b-field
                                class="mt-4"
                                label="Tipo de Documento *"
                                label-position="on-border"
                            >
                                <b-select
                                    @input="handleChangeTipoDocumento"
                                    placeholder="Selecciona una opción"
                                    v-model="form.tipo_documento_id"
                                    :loading="tiposDocumentosLoading"
                                    :disabled="tiposDocumentos.length === 0"
                                    expanded
                                    required
                                >
                                    <option
                                        v-for="option in tiposDocumentos"
                                        :value="option.id"
                                        :key="option.id"
                                    >
                                        {{ option.descripcion }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-message
                                v-if="verifyHasDocument.existe"
                                type="is-danger"
                            >
                                <div class="is-flex is-align-items-center">
                                    <b-icon icon="alert-circle" />
                                    <span class="ml-1">
                                        {{ verifyHasDocument.message }}
                                    </span>
                                </div>
                            </b-message>
                            <b-field
                                v-if="showGruposDocumentos"
                                class="mt-4"
                                label="Grupo de Documento *"
                                label-position="on-border"
                            >
                                <b-select
                                    placeholder="Selecciona una opción"
                                    v-model="form.grupo_documento_id"
                                    :loading="gruposDocumentosLoading"
                                    :disabled="gruposDocumentos.length === 0"
                                    expanded
                                    required
                                >
                                    <option
                                        v-for="option in gruposDocumentos"
                                        :value="option.id"
                                        :key="option.id"
                                    >
                                        {{ option.descripcion }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field
                                class="file is-primary mt-4"
                                :class="{ 'has-name': !!file }"
                            >
                                <b-upload
                                    v-model="file"
                                    class="file-label"
                                    accept=".pdf"
                                >
                                    <span class="file-cta">
                                        <b-icon
                                            class="file-icon"
                                            icon="upload"
                                        ></b-icon>
                                        <span class="file-label"
                                            >Subir archivo (.pdf)</span
                                        >
                                    </span>
                                    <span class="file-name" v-if="file">
                                        {{ file.name }}
                                    </span>
                                </b-upload>
                            </b-field>
                            <br />
                            <b-field>
                                <b-checkbox v-model="form.enviar_email">
                                    Notificar vía mail al empleado de un nuevo
                                    documento disponible
                                </b-checkbox>
                            </b-field>
                            <br />
                            <b-button
                                type="is-success"
                                native-type="submit"
                                :disabled="!file"
                                :loading="loading"
                                expanded
                            >
                                Cargar
                            </b-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import AutocompleteTrabajador from '@/components/shared/FormControls/AutocompleteTrabajador.vue';
import Message from '@/utils/Message';

export default {
    components: {
        AutocompleteTrabajador,
    },
    data() {
        return {
            loading: false,
            file: null,
            periodo: null,
            showGruposDocumentos: false,
            verifyHasDocument: {
                message: null,
                existe: null,
            },
            form: {
                trabajador_id: '',
                tipo_documento_id: '',
                grupo_documento_id: '',
                periodo: '',
                enviar_email: true,
            },
        };
    },
    async mounted() {
        await this.$store.dispatch('tiposDocumentos/get', {
            sort_param: 'descripcion',
            sort_order: 'ASC',
            per_page: 1000,
            page: 1,
        });
    },
    computed: {
        gruposDocumentos() {
            return this.$store.state.gruposDocumentos.data;
        },
        gruposDocumentosLoading() {
            return this.$store.state.gruposDocumentos.isLoading;
        },
        tiposDocumentos() {
            return this.$store.state.tiposDocumentos.data;
        },
        tiposDocumentosLoading() {
            return this.$store.state.tiposDocumentos.isLoading;
        },
        canVerify() {
            return (
                this.form.trabajador_id !== '' &&
                this.form.tipo_documento_id !== '' &&
                this.form.periodo !== ''
            );
        },
    },
    watch: {
        periodo: function (value) {
            this.form.periodo = moment(value).format('YYYY-MM-DD').toString();
        },
    },
    methods: {
        handleChangeTipoDocumento() {
            this.handleVerify();

            const tipo_documento = this.tiposDocumentos.find(
                (tipo) => tipo.id === this.form.tipo_documento_id
            );

            this.showGruposDocumentos = tipo_documento.cantidad_grupos_documentos > 0;

            if (this.showGruposDocumentos) {
                this.fetchGruposDocumentos();
            } else {
                this.form.grupo_documento_id = '';
            }
        },
        async handleVerify() {
            if (this.canVerify) {
                this.verification();
            }
        },
        async fetchGruposDocumentos() {
            await this.$store.dispatch('gruposDocumentos/get', {
                tipo_documento_id: this.form.tipo_documento_id,
            });
        },
        async verification() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'trabajadores/verifyHasDocument',
                    this.form
                );

                this.verifyHasDocument.message = res.message;
                this.verifyHasDocument.existe = res.existe;
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        async handleSubmit() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch('documentos/create', {
                    file: this.file,
                    data: {
                        ...this.form,
                        enviar_email: this.form.enviar_email ? 1 : 0,
                    },
                });
                this.cleanForm();
                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message, err?.errors);
            } finally {
                this.loading = false;
            }
        },
        cleanForm() {
            this.form.tipo_documento_id = '';
            this.form.grupo_documento_id = '';
            this.form.periodo = '';
            this.periodo = null;
            this.file = null;
            this.showGruposDocumentos = false;
            this.verifyHasDocument.message = null;
            this.verifyHasDocument.existe = null;
        },
    },
};
</script>

<style></style>
