import ProcesosService from '../services/procesos.service';

const initialState = {
    data: [],
    active: null,
    isLoading: false,
    pagination: {
        page: 1,
        per_page: 10,
        total: 0,
        total_pages: 0,
    },
};

export const procesos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, params) {
            commit('setIsLoading', true);
            return ProcesosService.get(params).then(
                res => {
                    commit('getSuccess', res);
                    commit('setIsLoading', false);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    commit('setIsLoading', false);
                    return Promise.reject(err);
                }
            );
        },
        getActive({ commit }, params) {
            return ProcesosService.getActive(params).then(
                res => {
                    commit('getActiveSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getActiveFailure');
                    return Promise.reject(err);
                }
            );
        },
        exportResultados({ commit }, proceso_id) {
            return ProcesosService.exportResultados(proceso_id);
        },
        setActive({ commit }, proceso) {
            commit('setActive', proceso);
        }
    },
    mutations: {
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        getSuccess(state, res) {
            state.data = res.data;
            state.pagination = res.pagination;
        },
        getFailure(state) {
            state.data = [];
            state.pagination = initialState.pagination;
        },
        getActiveSuccess(state, res) {
            state.active = res.data;
        },
        getActiveFailure(state) {
            state.active = {};
        },
        setActive(state, proceso) {
            state.active = proceso;
        }
    }
}
