import TiposDocumentosService from '../services/tipos-documentos.service';

const initialState = {
    data: [],
    selected: {},
    isLoading: false,
    blobUrl: null,
    info: {
        cantidades: { por_estado: {} },
        estadisticas: {},
    },
    verifyAptoParaSubirResponse: {
        value: false,
        message: '',
    },
    pagination: {
        page: 1,
        per_page: 5,
        total: 0,
        total_pages: 0,
    },
};

export const tiposDocumentos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, options) {
            commit('setIsLoading', true);
            return TiposDocumentosService.get(options).then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err);
                }
            );
        },
        getStats({ commit }, id) {
            return TiposDocumentosService.getStats(id).then(
                res => {
                    commit('getStatsSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getStatsFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        show({ commit }, id) {
            commit('setIsLoading', true);
            return TiposDocumentosService.show(id).then(
                res => {
                    commit('showSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('showFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        update({ commit }, { id, data }) {
            commit('setIsLoading', true);
            return TiposDocumentosService.update(id, data).then(
                res => {
                    commit('updateSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('updateFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        create({ commit }, data) {
            return TiposDocumentosService.create(data).then(
                res => {
                    commit('createSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            )
        },
        verifyAptoParaSubir({ commit }, id) {
            return TiposDocumentosService.verifyAptoParaSubir(id).then(
                res => {
                    commit('verifyAptoParaSubirSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('verifyAptoParaSubirFailure', err.response.data);
                    return Promise.reject(err.response.data);
                }
            );
        },
        viewEjemplo({ commit }, { id, data }) {
            return TiposDocumentosService.viewEjemplo(id, data).then(
                res => {
                    commit('viewEjemploSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('viewEjemploFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        }
    },
    mutations: {
        getSuccess(state, res) {
            state.data = res.data;
            state.isLoading = false;
            state.pagination = res.pagination;
        },
        getFailure(state) {
            state.data = [];
            state.isLoading = false;
            state.pagination = initialState.pagination;
        },
        showSuccess(state, res) {
            state.selected = res.data;
            state.isLoading = false;
        },
        showFailure(state) {
            state.selected = null;
            state.isLoading = false;
        },
        getStatsSuccess(state, res) {
            state.info = res.data;
        },
        getStatsFailure(state) {
            state.info = initialState.info;
        },
        createSuccess() {
            //
        },
        createFailure() {
            //
        },
        updateSuccess(state, res) {
            state.selected = res.data;
            state.isLoading = false;
        },
        updateFailure(state) {
            state.selected = null;
            state.isLoading = false;
        },
        verifyAptoParaSubirSuccess(state, res) {
            state.verifyAptoParaSubirResponse.value = true;
            state.verifyAptoParaSubirResponse.message = '';
        },
        verifyAptoParaSubirFailure(state, res) {
            state.verifyAptoParaSubirResponse.value = false;
            state.verifyAptoParaSubirResponse.message = res.message;
        },
        viewEjemploSuccess(state, url) {
            state.blobUrl = url;
        },
        viewEjemploFailure(state) {
            state.blobUrl = null;
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
    }
}
