<template>
    <b-modal v-model="isModalActive" :width="450">
        <div class="card">
            <div class="card-content">
                <div class="content">
                    <form @submit.prevent="handleSubmitInformacionCondicion">
                        <p>
                            Elige el banco donde deseas recibir el abono*:
                        </p>
                        <br />
                        <b-field
                            label="Seleccione banco"
                            label-position="on-border"
                        >
                            <b-select
                                required
                                expanded
                                v-model="formCondicion.banco_id"
                            >
                                <option
                                    v-for="banco of bancos"
                                    :key="banco.id"
                                    :value="banco.id"
                                >
                                    {{ banco.descripcion }}
                                </option>
                            </b-select>
                        </b-field>
                        <small>
                            Nota*: La Empresa realizará la apertura de la cuenta
                        </small>
                        <br />
                        <!--<b-field
                            label="Ingrese número de cuenta bancaria"
                            label-position="on-border"
                            :type="
                                validation.numero_cuenta_bancaria
                                    ? 'is-success'
                                    : 'is-danger'
                            "
                            :message="
                                validation.numero_cuenta_bancaria
                                    ? 'Número de cuenta válido'
                                    : 'Número de cuenta NO válido'
                            "
                        >
                            <b-input
                                v-model="formCondicion.numero_cuenta_bancaria"
                                name="numero_cuenta_bancaria"
                                required
                            />
                        </b-field>-->
                        <br />
                        <button
                            class="button is-block is-fullwidth is-primary"
                            :class="{ 'is-loading': loading }"
                            :disabled="loading || !validForm"
                            type="submit"
                        >
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import Message from '@/utils/Message';
export default {
    props: ['isModalActive'],
    data() {
        return {
            loading: false,
            loadingBancos: false,
            formCondicion: {
                banco_id: '',
                // numero_cuenta_bancaria: '',
            },
            validation: {
                banco_id: false,
                // numero_cuenta_bancaria: false,
            },
        };
    },
    computed: {
        bancos() {
            return this.$store.state.bancos.data;
        },
        validForm() {
            // return this.validation.numero_cuenta_bancaria;
            return this.validation.banco_id;
        },
    },
    watch: {
        formCondicion: {
            handler: function (newValue, oldValue) {
                /*const valid = validateNumeroCuenta(
                    newValue.numero_cuenta_bancaria,
                    newValue.banco_id
                );

                this.validation.numero_cuenta_bancaria = valid;*/
                this.validation.banco_id = newValue.banco_id !== '';
            },
            deep: true,
        },
    },
    mounted() {
        this.fetchBancos();
    },
    methods: {
        async handleSubmitInformacionCondicion() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'me/update',
                    this.formCondicion
                );

                this.clear();
                this.$emit('toggle-view-modal', false);

                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        async fetchBancos() {
            this.loadingBancos = true;
            try {
                await this.$store.dispatch('bancos/get');
            } catch (err) {
                console.log(err);
            } finally {
                this.loadingBancos = false;
            }
        },
        clear() {
            this.formCondicion.banco_id = '';
            // this.formCondicion.numero_cuenta_bancaria = '';
        },
    },
};
</script>

<style>
</style>
