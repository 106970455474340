<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Tipos de Documentos</h2>
        </div>
        <div class="is-relative">
            <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
            <div class="buttons">
                <b-button
                    icon-left="plus"
                    style="border-style: dashed"
                    @click="showForm = !showForm"
                >
                    Crear
                </b-button>
            </div>
            <b-field label="Buscar" label-position="on-border">
                <b-input
                    placeholder="Buscar por descripcion"
                    v-model="criterio"
                    name="criterio"
                    @input="handleSearch"
                    icon-right="close-circle"
                    icon-right-clickable
                    @icon-right-click="handleClearCriterio"
                ></b-input>
            </b-field>
            <br />
            <ul>
                <li v-for="tipo of tiposDocumentos" :key="tipo.id">
                    <router-link
                        :to="'/a/documentos/tipos-documentos/' + tipo.id"
                        class="link"
                    >
                        <div class="card mb-2">
                            <div class="card-content">
                                <div
                                    class="
                                        content
                                        is-flex
                                        is-justify-content-space-between
                                        is-align-items-center
                                    "
                                >
                                    <div class="has-text-weight-semibold">
                                        <div class="mb-2">
                                            <span class="has-text-primary-dark">
                                                {{ tipo.descripcion }}
                                            </span>
                                            <b-tag
                                                size="is-small"
                                                :type="
                                                    tipo.activo
                                                        ? 'is-success'
                                                        : 'is-danger'
                                                "
                                            >
                                                {{
                                                    tipo.activo
                                                        ? 'Activo'
                                                        : 'No Activo'
                                                }}
                                            </b-tag>
                                        </div>
                                        <small class="has-text-grey"
                                            >Requiere Firma del TRABAJADOR:
                                            {{
                                                tipo.require_firma ? 'SI' : 'NO'
                                            }}</small
                                        >
                                        <br />
                                        <small class="has-text-grey"
                                            >Requiere Firma del EMPLEADOR:
                                            {{
                                                tipo.require_firma_empleador
                                                    ? 'SI'
                                                    : 'NO'
                                            }}</small
                                        >
                                        <br />
                                        <small class="has-text-grey"
                                            >Creado:
                                            {{
                                                tipo.created_at | moment
                                            }}</small
                                        >
                                    </div>
                                    <div>
                                        <div class="arrow-selected">
                                            <b-icon
                                                icon="chevron-right"
                                                size="is-medium"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <b-pagination
                v-if="tiposDocumentos.length > 0"
                v-model="pagination.page"
                :total="paginationTotal"
                :per-page="pagination.per_page"
                @change="fetchTiposDocumentos"
            />
            <div
                v-if="!isLoading && tiposDocumentos.length === 0"
                class="is-flex is-justify-content-center"
            >
                <h4 class="subtitle has-text-primary-dark">
                    Aún no tienes tipos de documentos creados
                </h4>
            </div>
        </div>

        <!-- Modal Crear Tipo Documento -->
        <b-modal v-model="showForm" :width="450">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title">Crear Tipo Documento</div>
                </div>
                <div class="card-content">
                    <form @submit.prevent="handleSubmit">
                        <b-field label="Descripcion" label-position="on-border">
                            <b-input
                                v-model="form.descripcion"
                                name="descripcion"
                                required
                            ></b-input>
                        </b-field>
                        <b-field>
                            <b-checkbox v-model="form.require_firma_empleador">
                                Requiere Firma del Empleador
                            </b-checkbox>
                        </b-field>
                        <b-field>
                            <b-checkbox v-model="form.require_firma">
                                Requiere Firma del Trabajador
                            </b-checkbox>
                        </b-field>
                        <button
                            class="button is-block is-fullwidth is-primary"
                            :class="{ 'is-loading': loading }"
                            :disabled="loading || !enableForm"
                            type="submit"
                        >
                            Cargar
                        </button>
                    </form>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import Message from '@/utils/Message';

export default {
    data() {
        return {
            showForm: false,
            form: {
                descripcion: '',
                require_firma: true,
                require_firma_empleador: false,
            },
            criterio: '',
            timer: null,
            loading: false,
            pagination: {
                per_page: 5,
                page: 1,
            },
        };
    },
    mounted() {
        this.fetchTiposDocumentos();
    },
    computed: {
        tiposDocumentos() {
            return this.$store.state.tiposDocumentos.data;
        },
        isLoading() {
            return this.$store.state.tiposDocumentos.isLoading;
        },
        enableForm() {
            return this.form.descripcion !== '';
        },
        paginationTotal() {
            return this.$store.state.tiposDocumentos.pagination.total;
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY hh:mm:ss');
        },
    },
    methods: {
        async handleSubmit() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'tiposDocumentos/create',
                    this.form
                );
                this.fetchTiposDocumentos();
                this.clearForm();
                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        async fetchTiposDocumentos() {
            await this.$store.dispatch('tiposDocumentos/get', {
                criterio: this.criterio,
                ...this.pagination
            });
        },
        async handleSearch() {
            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                this.fetchTiposDocumentos();
            }, 500);
        },
        clearForm() {
            (this.form.descripcion = ''), (this.form.require_firma = false);
            this.form.require_firma_empleador = true;
            this.showForm = false;
        },
        handleClearCriterio() {
            this.criterio = '';
            this.fetchTiposDocumentos();
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    .card {
        .arrow-selected {
            display: none;
        }
    }

    &:hover {
        .arrow-selected {
            display: block;
        }
    }
}
</style>
