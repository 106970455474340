<template>
    <header>
        <b-navbar class="has-shadow">
            <template #brand>
                <!-- Logo -->
                <b-navbar-item
                    tag="router-link"
                    class="brand"
                    :to="{ path: rutaHome }"
                >
                    <img
                        class="brand__isotype"
                        :src="
                            require('@/assets/images/branding/logo-grupo-verfrut.png')
                        "
                        style="max-height: 33px; height: 33px;"
                        alt="Empresa Logo"
                        title="Empresa"
                    />
                </b-navbar-item>
            </template>

            <template v-if="this.public" #end>
                <b-navbar-item tag="router-link" to="/login">
                    <b-icon icon="account" size="is-small"></b-icon>
                    <span>Iniciar Sesión</span>
                </b-navbar-item>
            </template>

            <template v-else #end>
                <!-- Notifications Bell -->
                <!-- <notifications-bell v-if="showNotificationBell" />
                <b-navbar-item v-else tag="router-link" to="/notificaciones">
                    Notificaciones
                    <b-tag type="is-warning"><b>3</b></b-tag>
                </b-navbar-item> -->

                <!-- User Options Dropdown -->
                <b-navbar-dropdown right>
                    <!-- Label -->
                    <template #label>
                        <!-- User Image -->
                        <div class="mr-2">
                            <img
                                v-if="fotoPerfil"
                                :src="fotoPerfil"
                                :alt="perfil.trabajador.usuario.username || ''"
                                style="
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                "
                            />
                            <no-user-image
                                v-else
                                size="small"
                                :trabajador="perfil.trabajador"
                                style="
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                "
                            />
                        </div>
                        <div
                            v-if="perfil.trabajador.empresa"
                            class="
                                is-flex-direction-column is-align-items-flex-end
                            "
                        >
                            <router-link to="/account" title="Cuenta">
                                <span
                                    style="
                                        font-weight: 600;
                                        text-decoration: underline;
                                    "
                                >
                                    {{ trabajadorNombreCompleto }}
                                </span>
                            </router-link>
                            <br />
                            <div>
                                Empresa:&nbsp;<b class="has-text-primary">{{
                                    perfil.trabajador.empresa.razon_social
                                }}</b>
                            </div>
                        </div>
                    </template>
                    <!-- Items -->
                    <b-navbar-item tag="router-link" :to="{ path: '/account' }">
                        Ver Perfil
                    </b-navbar-item>
                    <hr class="dropdown-divider" />
                    <b-navbar-item @click="setisModalSeleccionarPerfil(true)">
                        Cambiar Perfil
                    </b-navbar-item>
                    <b-navbar-item @click="setIsModalCambiarEmail(true)">
                        Cambiar Email
                    </b-navbar-item>
                    <b-navbar-item @click="setIsModalCambiarContrasena(true)">
                        Cambiar Contraseña
                    </b-navbar-item>
                    <hr class="dropdown-divider" />
                    <b-navbar-item @click.prevent="logOut">
                        <b-icon icon="logout" /> <span class="ml-2">Salir</span>
                    </b-navbar-item>
                </b-navbar-dropdown>
            </template>
        </b-navbar>
        <!-- Modal Cambiar Email -->
        <transition name="fade">
            <div class="modal is-active" v-if="isModalCambiarEmail">
                <div
                    class="modal-background"
                    @click="setIsModalCambiarEmail(false)"
                ></div>
                <div class="modal-card">
                    <section class="modal-card-body m-2">
                        <form @submit.prevent="handleCambiarEmail">
                            <b-field
                                label="Email Actual"
                                label-position="on-border"
                            >
                                <b-input
                                    :value="perfil.trabajador.usuario.email"
                                    name="old-email"
                                    type="email"
                                    disabled
                                />
                            </b-field>
                            <b-field
                                label="Ingrese Nuevo Email"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="forms.cambiarEmail.email"
                                    name="email"
                                    type="email"
                                    required
                                />
                            </b-field>
                            <b-field
                                label="Ingrese Contraseña"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="forms.cambiarEmail.password"
                                    name="contraseña"
                                    type="password"
                                    password-reveal
                                    required
                                />
                            </b-field>
                            <br />
                            <button
                                class="button is-block is-fullwidth is-primary"
                                :class="{ 'is-loading': loading }"
                                :disabled="loading"
                                type="submit"
                            >
                                Cambiar Email
                            </button>
                        </form>
                    </section>
                </div>
                <button
                    class="modal-close is-large"
                    aria-label="close"
                    @click="setIsModalCambiarEmail(false)"
                ></button>
            </div>
        </transition>
        <!-- Modal Cambiar Contraseña -->
        <transition name="fade">
            <div class="modal is-active" v-if="isModalCambiarContrasena">
                <div
                    class="modal-background"
                    @click="setIsModalCambiarContrasena(false)"
                ></div>
                <div class="modal-card">
                    <section class="modal-card-body m-2">
                        <form @submit.prevent="handleCambiarContrasena">
                            <b-field
                                label="Contraseña Actual"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="forms.cambiarContrasena.password"
                                    name="password"
                                    type="password"
                                    password-reveal
                                    required
                                />
                            </b-field>
                            <b-field
                                label="Ingrese Nuevo Contraseña"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="
                                        forms.cambiarContrasena.new_password
                                    "
                                    name="new_password"
                                    type="password"
                                    password-reveal
                                    required
                                />
                            </b-field>
                            <b-field
                                label="Ingrese Contraseña"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="
                                        forms.cambiarContrasena
                                            .new_password_confirmation
                                    "
                                    name="new_password_confirmation"
                                    type="password"
                                    password-reveal
                                    required
                                />
                            </b-field>
                            <br />
                            <button
                                class="button is-block is-fullwidth is-primary"
                                :class="{ 'is-loading': loading }"
                                :disabled="loading"
                                type="submit"
                            >
                                Cambiar Contraseña
                            </button>
                        </form>
                    </section>
                </div>
                <button
                    class="modal-close is-large"
                    aria-label="close"
                    @click="setIsModalCambiarContrasena(false)"
                ></button>
            </div>
        </transition>
        <!-- Modal Seleccionar Perfil -->
        <modal-seleccionar-perfil />
    </header>
</template>

<script>
import Pusher from 'pusher-js';

import Message from '@/utils/Message';
import NoUserImage from '@/components/shared/NoUserImage.vue';
import ModalSeleccionarPerfil from '@/components/shared/Modals/ModalSeleccionarPerfil.vue';
import NotificationsBell from '@/components/shared/NotificationsBell.vue';

import { BASE_URL, PUSHER_KEY, PUSHER_CONFIG } from '@/config';

export default {
    name: 'TheNavbar',
    components: {
        NoUserImage,
        ModalSeleccionarPerfil,
        NotificationsBell,
    },
    props: {
        public: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            base_url: BASE_URL,
            forms: {
                cambiarEmail: {
                    email: '',
                    password: '',
                },
                cambiarContrasena: {
                    password: '',
                    new_password: '',
                    new_password_confirmation: '',
                },
            },
            windowWidth: window.innerWidth,
        };
    },
    mounted() {
        //this.suscribe();
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
        };
    },
    watch: {
        perfil(value) {
            this.suscribe(value.id);
            return;
        },
    },
    computed: {
        showNotificationBell() {
            return this.windowWidth >= 1024;
        },
        perfil() {
            return this.$store.state.me.perfil;
        },
        rutaHome() {
            return this.perfil.descripcion === 'Recursos Humanos'
                ? '/a'
                : this.perfil.descripcion === 'Empleado'
                ? '/u'
                : '/';
        },
        fotoPerfil() {
            return this.$store.state.me.perfil.trabajador.usuario.ruta_imagen
                ? `${this.base_url}/${this.$store.state.me.perfil.trabajador.usuario.ruta_imagen}`
                : null;
        },
        fotoEmpresa() {
            return this.$store.state.me.perfil.trabajador.empresa.ruta_imagen
                ? `${this.base_url}/${this.$store.state.me.perfil.trabajador.empresa.ruta_imagen}`
                : null;
        },
        trabajadorNombreCompleto() {
            return this.perfil.trabajador.nombre
                ? `${this.perfil.trabajador.nombre} ${this.perfil.trabajador.apellido_paterno} ${this.perfil.trabajador.apellido_materno}`
                : this.perfil.trabajador.usuario.username;
        },
        isModalCambiarEmail() {
            return this.$store.state.ui.modals.cambiarEmail;
        },
        isModalCambiarContrasena() {
            return this.$store.state.ui.modals.cambiarContrasena;
        },
    },
    methods: {
        suscribe(id) {
            new Pusher(PUSHER_KEY, PUSHER_CONFIG)
                .subscribe(`perfiles.${id}`)
                .bind('notification-event', data => {
                    const { message, type } = data;

                    this.$store.dispatch(
                        'procesos/getActive',
                        { tipo_proceso: 'importacion-masiva-trabajadores' }
                    );

                    this.$buefy.notification.open({
                        message: message,
                        type: `is-${type}`,
                        indefinite: true,
                        queue: false,
                    });
                });
        },
        async logOut() {
            try {
                await this.$store.dispatch('auth/logout');
                window.location.replace('/login');
            } catch (e) {
                // this.$router.push('/login');
            }
        },
        setIsModalCambiarEmail(value) {
            this.$store.dispatch('ui/setIsModalCambiarEmail', value);
        },
        setIsModalCambiarContrasena(value) {
            this.$store.dispatch('ui/setIsModalCambiarContrasena', value);
        },
        setisModalSeleccionarPerfil(value) {
            this.$store.dispatch('ui/setisModalSeleccionarPerfil', value);
        },
        setIsModalSubirImagen(value) {
            this.$store.dispatch('ui/setIsModalSubirImagen', value);
        },
        async handleCambiarEmail() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'me/updateEmail',
                    this.forms.cambiarEmail
                );

                await this.setIsModalCambiarEmail(false);
                await this.$store.dispatch('me/get');
                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        async handleCambiarContrasena() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch(
                    'me/updatePassword',
                    this.forms.cambiarContrasena
                );

                await this.setIsModalCambiarContrasena(false);
                Message.showSuccessMessage(this, res.message);

                await this.$store.dispatch('auth/logout');
                await this.$router.push('/login');
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.brand {
    &__isotype {
        margin-left: 0.7rem;
    }

    &__logotype {
        margin-left: 0.5rem;
        display: none;
    }

    &:hover {
        .brand__logotype {
            display: block;
            opacity: 1;
            animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-name: fadeIn;
            animation-duration: 0.5s;
            -webkit-animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
            -webkit-animation-timing-function: ease-in-out;
            visibility: visible !important;
        }
    }
}

@keyframes fadeIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    60% {
        transform: scale(1.1);
    }
    80% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(1.1);
    }
    80% {
        -webkit-transform: scale(0.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}
</style>
