<template>
    <div>
        <b-loading
            :is-full-page="true"
            v-model="loadingViewDocument"
        ></b-loading>
        <div class="card">
            <div class="card-content">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <span class="mr-1">Por página:</span>
                            <b-select
                                v-model="pagination.per_page"
                                @input="fetchDocuments"
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </b-select>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <b-tag type="is-light"
                                ><b class="mr-1">{{ totalDocumentos }}</b
                                >registros</b-tag
                            >
                        </div>
                        <div class="level-item">
                            <b-button
                                icon-left="download"
                                :disabled="totalDocumentos === 0"
                                @click="exportModal = true"
                            >
                                Exportar
                            </b-button>
                        </div>
                    </div>
                </div>
                <b-table
                    :data="documentos"
                    :loading="isLoadingDocumentos"
                    :paginated="true"
                    :per-page="pagination.per_page"
                    :current-page="pagination.page"
                    :total="totalDocumentos"
                    @page-change="handleChangePage"
                    backend-pagination
                >
                    <b-table-column field="download-doc" v-slot="props">
                        <b-tooltip label="Descargar documento" type="is-dark">
                            <a @click.prevent="downloadDocument(props.row)">
                                <b-icon
                                    icon="file-pdf-outline"
                                    type="is-danger is-light"
                                />
                            </a>
                        </b-tooltip>
                    </b-table-column>

                    <b-table-column
                        field="trabajador"
                        label="Trabajador / DNI"
                        v-slot="props"
                    >
                        <div class="is-flex is-flex-direction-column">
                            <span
                                class="
                                    has-text-primary-dark has-text-weight-light
                                "
                                >{{ props.row.apellido_paterno }}
                                {{ props.row.apellido_materno }}
                                {{ props.row.nombre }}</span
                            >
                            <div class="is-flex is-align-content-center">
                                <b-tooltip
                                    :label="props.row.pais"
                                    type="is-black"
                                >
                                    <img
                                        :src="props.row.pais_ruta_imagen"
                                        class="image is-16x16"
                                    />
                                </b-tooltip>
                                <small class="ml-1">{{ props.row.dni }}</small>
                            </div>
                        </div>
                    </b-table-column>

                    <b-table-column
                        field="tipo_documento"
                        label="Tipo Documento"
                        v-slot="props"
                    >
                        {{ props.row.tipo_documento }}
                    </b-table-column>

                    <b-table-column
                        field="grupo_documento"
                        label="Grupo Documento"
                        v-slot="props"
                    >
                        {{ props.row.grupo_documento }}
                    </b-table-column>

                    <b-table-column
                        field="periodo"
                        label="Periodo"
                        v-slot="props"
                    >
                        {{ props.row.periodo | moment }}
                    </b-table-column>

                    <b-table-column
                        field="estado"
                        label="Estado"
                        v-slot="props"
                    >
                        <b-tooltip
                            :label="
                                `Modificado: ${props.row.estado_created_at}`
                            "
                            type="is-dark"
                        >
                            <b-tag :type="props.row.estado_color">
                                {{ props.row.estado_descripcion }}
                            </b-tag>
                        </b-tooltip>
                    </b-table-column>

                    <b-table-column field="prev-doc" v-slot="props">
                        <b-tooltip label="Ver documento" type="is-dark">
                            <a
                                target="_blank"
                                @click.prevent="viewDocument(props.row)"
                            >
                                <b-icon icon="eye" />
                            </a>
                        </b-tooltip>
                        <b-tooltip label="Eliminar documento" type="is-dark">
                            <a @click.prevent="confirmDelete(props.row)">
                                <b-icon icon="trash-can" />
                            </a>
                        </b-tooltip>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">No hay registros</div>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal v-model="isActive">
            <iframe
                :src="`${blobUrl}`"
                style="width: 100%; min-height: 90vh"
                frameborder="1"
            ></iframe>
        </b-modal>

        <b-modal v-model="exportModal" :width="640">
            <div class="box is-relative">
                <b-loading
                    v-model="exportLoading"
                    :is-full-page="false"
                ></b-loading>
                <ul>
                    <li
                        v-for="exportOption of exportOptions"
                        :key="exportOption.name"
                    >
                        <a
                            class="box m-3"
                            @click.prevent="exportData(exportOption.value)"
                        >
                            <b
                                class="
                                    has-text-primary-dark
                                    is-flex is-align-items-center
                                "
                            >
                                <b-icon icon="download" /><span>{{
                                    exportOption.name
                                }}</span>
                            </b>
                        </a>
                    </li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import { BASE_URL } from '@/config';
import Message from '@/utils/Message';
import Utils from '@/utils/Utils';

export default {
    props: ['filters', 'pagination'],
    data() {
        return {
            baseUrl: BASE_URL,
            documento: {},
            blobUrl: null,
            exportLoading: false,
            exportModal: false,
            exportOptions: [
                {
                    value: 'csv',
                    name: 'CSV',
                },
                {
                    value: 'ods',
                    name: 'ODS',
                },
                {
                    value: 'xlsx',
                    name: 'XLSX',
                },
            ],
            isActive: false,
            loadingViewDocument: false,
        };
    },
    computed: {
        documentos() {
            return this.$store.state.documentos.data;
        },
        isLoadingDocumentos() {
            return this.$store.state.documentos.isLoading;
        },
        totalDocumentos() {
            return this.$store.state.documentos.pagination.total;
        },
        totalPages() {
            return this.$store.state.documentos.pagination.total_pages;
        },
    },
    mounted() {
        this.$store.dispatch('documentos/clean');
    },
    methods: {
        handleChangePage(page) {
            this.pagination.page = page;
            this.fetchDocuments();
        },
        confirmDelete(document) {
            this.$buefy.dialog.confirm({
                message: '¿Desea eliminar este registro?',
                onConfirm: () => this.deleteDocument(document),
            });
        },
        async viewDocument(document) {
            this.blobUrl = null;
            this.loadingViewDocument = true;
            try {
                const res = await this.$store.dispatch(
                    'documentos/view',
                    document.id
                );
                this.isActive = true;
                this.documento = document;
                this.blobUrl = res;
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loadingViewDocument = false;
            }
        },
        async deleteDocument(document) {
            try {
                await this.$store.dispatch('documentos/delete', document.id);

                Message.showSuccessMessage(this, 'Documento eliminado');

                this.fetchDocuments();
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            }
        },
        async downloadDocument(document) {
            this.documento = document;
            const res = await this.$store.dispatch(
                'documentos/view',
                document.id
            );
            this.blobUrl = res;
            Utils.downloadFileFromUrl(this.blobUrl, 'document', 'pdf');
        },
        async exportData(exportOption) {
            try {
                this.exportLoading = true;
                await this.$store.dispatch('documentos/export', {
                    format: exportOption,
                    filters: this.filters,
                });
                Message.showSuccessMessage(this, 'Documento obtenido');
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.exportLoading = false;
            }
        },
        async fetchDocuments() {
            try {
                this.$store.dispatch('documentos/setLoading', true);

                const res = await this.$store.dispatch('documentos/get', {
                    ...this.filters,
                    ...this.pagination,
                });
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.$store.dispatch('documentos/setLoading', false);
            }
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('MM/YYYY');
        },
    },
};
</script>

<style>
</style>
