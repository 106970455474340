import GruposDocumentosService from '@/services/grupos-documentos.service';

const initialState = {
    data: [],
    isLoading: false,
    pagination: {
        page: 1,
        per_page: 5,
        total: 0,
        total_pages: 0,
    },
};

export const gruposDocumentos = {
    namespaced: true,
    state: initialState,
    actions: {
        get({ commit }, params) {
            commit('setIsLoading', true);
            return GruposDocumentosService.get(params).then(
                res => {
                    commit('getSuccess', res);
                    return Promise.resolve(res);
                },
                err => {
                    commit('getFailure');
                    return Promise.reject(err);
                }
            );
        },
        create({ commit }, data) {
            return GruposDocumentosService.create(data).then(
                res => {
                    commit('createSuccess');
                    return Promise.resolve(res);
                },
                err => {
                    commit('createFailure');
                    return Promise.reject(err.response.data);
                }
            );
        },
        update(_, { id, data }) {
            return GruposDocumentosService.update(id, data).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
        delete(_, id) {
            return GruposDocumentosService.delete(id).then(
                res => {
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err.response.data);
                }
            );
        },
    },
    mutations: {
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        getSuccess(state, res) {
            state.data = res.data;
            state.pagination = res.pagination;
            state.isLoading = false;
        },
        getFailure(state) {
            state.data = [];
            state.isLoading = false;
            state.pagination = initialState.pagination;
        },
        createSuccess(state) {
            //
        },
        createFailure(state) {
            //
        }
    },
};
