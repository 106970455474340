<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Carga Masiva</h2>
        </div>
        <div class="columns">
            <div
                class="
                    column
                    is-half-desktop is-offset-one-quarter-desktop is-relative
                "
            >
                <b-loading
                    v-model="loadingProcesoActivo"
                    :is-full-page="false"
                />
                <div class="card" v-if="!procesoActivo">
                    <div class="card-content">
                        <div class="content">
                            <ol>
                                <li>
                                    <span
                                        class="has-text-weight-bold"
                                        style="font-size: 0.9rem"
                                        >Haz una lista de los usuarios</span
                                    ><br />Crea un archivo CSV para armar la
                                    lista de usuarios.
                                    <a
                                        href="#"
                                        @click.prevent="downloadExample"
                                        class="
                                            has-text-primary-dark
                                            is-underlined
                                        "
                                        >Puedes bajarte este ejemplo para
                                        completar.</a
                                    >
                                    Es importante que respetes tanto la cabecera
                                    del archivo como primera linea.<br />
                                    <small class="has-text-primary-dark">
                                        *El código de los paises esta
                                        determinado por el estándar
                                        <a
                                            href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
                                            target="_blank"
                                            class="has-text-weight-semibold"
                                        >
                                            ISO 3166-1 alfa-2 </a
                                        >. </small
                                    ><br />
                                    <small class="has-text-primary-dark">
                                        *El archivo CSV debe tener como
                                        separador punto y coma <code>;</code> y
                                        tener codificación <code>UTF-8</code>.
                                    </small>
                                </li>
                                <li>
                                    <span
                                        class="has-text-weight-bold"
                                        style="font-size: 0.9rem"
                                        >Selección de opciones de
                                        actualización</span
                                    ><br />
                                    <p>
                                        Para cada una de las lineas del archivo,
                                        esta actualización hará lo siguiente:
                                    </p>
                                    <b-field>
                                        <b-checkbox
                                            v-model="opcionesCarga.crear"
                                            disabled
                                            >Crear usuarios nuevos</b-checkbox
                                        >
                                    </b-field>
                                    <b-field>
                                        <b-checkbox
                                            v-model="opcionesCarga.modificar"
                                            >Modificar usuarios
                                            existentes</b-checkbox
                                        >
                                    </b-field>
                                    <b-field>
                                        <b-checkbox
                                            v-model="opcionesCarga.enviar_email"
                                            >Enviar email de notificación a los
                                            usuarios</b-checkbox
                                        >
                                    </b-field>
                                    <small class="has-text-primary-dark"
                                        >*A los usuarios que se den de alta, se
                                        les asignará un perfil de
                                        empleado</small
                                    >
                                </li>
                                <li>
                                    <span
                                        class="has-text-weight-bold"
                                        style="font-size: 0.9rem"
                                        >Subir archivo en formato CSV</span
                                    ><br /><br />
                                    <b-field class="file">
                                        <b-upload
                                            v-model="file"
                                            accept=".csv"
                                            expanded
                                        >
                                            <a
                                                class="
                                                    button
                                                    is-primary is-fullwidth
                                                "
                                            >
                                                <b-icon icon="upload"></b-icon>
                                                <span v-if="!file">{{
                                                    'Subir (.csv)'
                                                }}</span>
                                                <span v-else>{{
                                                    file.name
                                                }}</span>
                                            </a>
                                        </b-upload>
                                    </b-field>
                                </li>
                                <div
                                    class="
                                        is-flex is-flex-direction-row-reverse
                                    "
                                >
                                    <b-button
                                        type="is-success"
                                        @click="handleProcesar"
                                        :loading="loading"
                                        :disabled="!file"
                                    >
                                        Procesar Archivo
                                    </b-button>
                                </div>
                            </ol>
                        </div>
                    </div>
                </div>
                <div v-else class="card">
                    <div class="card-content">
                        <div class="content">
                            <b-notification
                                type="is-warning"
                                :closable="false"
                                has-icon
                                aria-close-label="Close notification"
                                role="alert"
                            >
                                Actualmente hay un proceso de
                                <b>{{ procesoActivo.tipo.descripcion }}</b>
                                ejecutandose. Por favor, espere que termine que
                                termine este proceso para ejecutar otro similar.
                            </b-notification>
                            <br />
                            <h5>Información:</h5>
                            <ul>
                                <li>
                                    <b>Cargado por:</b>
                                    {{
                                        procesoActivo.perfil.trabajador
                                            | nombreCompletoTrabajador
                                    }}
                                </li>
                                <li>
                                    <b>Fecha y hora:</b>
                                    {{ procesoActivo.created_at | moment }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Message from '@/utils/Message';
export default {
    data() {
        return {
            loading: false,
            loadingProcesoActivo: false,
            file: null,
            opcionesCarga: {
                crear: true,
                modificar: true,
                enviar_email: true,
            },
            resultadoCarga: {
                mensaje: '',
                clase: '',
            },
        };
    },
    mounted() {
        this.fetchActiveProceso();
    },
    computed: {
        procesoActivo() {
            return this.$store.state.procesos.active;
        },
    },
    methods: {
        async handleProcesar() {
            this.loading = true;
            try {
                const { message } = await this.$store.dispatch(
                    'trabajadores/import',
                    this.file
                );
                Message.showSuccessMessage(this, message);
                this.fetchActiveProceso();
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        async downloadExample() {
            try {
                await this.$store.dispatch(
                    'trabajadores/downloadImportStructure'
                );
            } catch (err) {
                Message.showErrorMessage(this, 'Error al descargar archivo');
            }
        },
        async fetchActiveProceso() {
            try {
                this.loadingProcesoActivo = true;
                await this.$store.dispatch(
                    'procesos/getActive',
                    { tipo_proceso: 'importacion-masiva-trabajadores' }
                );
            } catch (err) {
                Message.showErrorMessage(
                    this,
                    'Error al obtener información del proceso'
                );
            } finally {
                this.loadingProcesoActivo = false;
            }
        },
    },
    filters: {
        nombreCompletoTrabajador: function ({
            nombre,
            apellido_paterno,
            apellido_materno,
        }) {
            return `${nombre} ${apellido_paterno} ${apellido_materno}`;
        },
        moment: function (date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss');
        },
    },
};
</script>

<style lang="scss" scoped>
.content li {
    margin-bottom: 20px;
}
</style>
