import Utils from '@/utils/Utils';
import authHeader from './auth-header';
import API from '@/services/base';

class ProcesosService {
    get(params) {
        return API.get('/procesos', { headers: authHeader(), params }).then(res => {
            return res.data;
        });
    }

    getActive(params) {
        return API.get('/procesos/active', { headers: authHeader(), params }).then(res => {
            return res.data;
        });
    }

    async exportResultados(proceso_id) {
        const res = await API.get(`/procesos/${proceso_id}/export`, { responseType: 'blob', headers: authHeader() });
        Utils.downloadFileFromResponse(res, 'resultado_proceso', 'xlsx');
    }
}

export default new ProcesosService();
