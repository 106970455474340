import authHeader from './auth-header';
import API from '@/services/base';

class GruposDocumentosService {
    get(params) {
        return API.get(`/grupo-documentos`, { headers: authHeader(), params }).then(res => {
            return res.data;
        });
    }
    create(data) {
        return API.post(`/grupo-documentos`, data, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }

    update(id, data) {
        return API.put(`/grupo-documentos/${id}`, data, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }
    delete(id) {
        return API.delete(`/grupo-documentos/${id}`, { headers: authHeader() }).then(res => {
            return res.data;
        });
    }
}

export default new GruposDocumentosService();
